:root {
  --app-font: 'Quicksand', 'Lato', sans-serif;
  --neutral-color: white;
  
  background: linear-gradient( to right, rgba(41, 181, 246, 1) 0%, rgba(41, 181, 246, 1) 20%, rgba(29, 233, 182, 1) 83%, rgba(29, 233, 182, 1) 100% );

  /* background: url('../src/assets/img/background.jpg') center; */
  background-size: cover;
}

.App {
  background-color: rgb(191, 225, 255);
  text-align: center;
  font-family: var(--app-font);
  user-select: none;
}
.RouletteText{
  font-size: xx-large;
  text-align: center;
  color: var(--color-text-1);
  font-family: 'Lato';
}
.RouletteText2 {
  font-size: xx-large;
  text-align: center;
  color: var(--color-text-2);
  font-family: 'Lato';
  font-weight: 600;
}
.content{
  color: white;
  width: auto;
  margin: auto;
  justify-content: center;
  align-items: center;
}
.swal-overlay {
    position: absolute;
}
html, body {
  min-height: 100%;
  overflow: hidden;
  
}