
.Titulo{
    text-align: center;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    margin-bottom: 0px;
    margin-top: 50px;
}
.titulo1{
    padding-left: 20px !important;
    padding-top: 20px !important;
    margin-bottom: -20px !important;
}
.Titulo2{
    text-align: center;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    margin-bottom: 0px;
    margin-top: 30px;
    min-width: 100px;
    
    
}
.Titulo3{
    text-align: center;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    margin-bottom: 0px;
    margin-top: 30px;
    margin-right: 24px;
    min-width: 100px;
}

.helpIcon{
    padding-left: 10px;
    display: inline;
    padding-top: 10px;
}
.MuiGrid-grid-xs-1 {

    padding-inline-start: 0px !important;
    padding-inline-end: 0px !important;
}

.textSelectorEditing{
    font-size:  larger !important;
    
}
.configSide{
    background:white;
}

.MuiGrid-spacing-xs-3 > .MuiGrid-item{
    padding-left: 24px !important;
}



.ColorPalet{
    block-size: 50px;
   margin-left:50px;
}

.MuiTab-wrapper {
    font-size: 18px;
}
    

.list_Of_Items{
    margin-top:20px;
}

@media (min-width: 375px) {
    .scroller1 {
        max-height: 470px;
        height: 100px;
    }

}

@media (min-width: 768px) {
    .scroller1 {
        max-height: 550px;
        height: 700px;
    }

}


@media (min-width: 1080px) {
    .scroller1 {
        max-height: 800px;
        height: 900px;
    }

}
.scroller1 {
   
    max-height: 635px;
    overflow-y: auto;
    overflow-x: hidden;
    scroll-snap-type: y mandatory;
    scroll-snap-type: x mandatory;
}



.scroller1 section {
    scroll-snap-align: start;
   
}

.scroller {
    max-height: 400px;
    margin-right: -35px;
    height: 700px; 
    overflow-y: auto;
    overflow-x: hidden;
    scroll-snap-type: y mandatory;
}

.scroller section {
    scroll-snap-align: start;
    scroll-margin: 40px;
}

.makeStyles-root-4{
    margin-left: 15px;
}
.backgroundupload{

    max-width: 500px;
}
/* PRUEBAS */
.MuiGrid-spacing-xs-2 {
    width: calc(100% + 16px);
    margin: -8px;
    place-content: center;
  
    }
.MuiGrid-grid-xs-true {
    flex-grow: 1;
    max-width: 100%;
    flex-basis: 0;
    text-align: -webkit-center;
}
.MuiTabs-flexContainer {
    display: flex;
    place-content: center;
}

/* .MuiSpeedDialAction-tooltipPlacementLeft {
   
    right: -140%;
    
} */
.MuiSpeedDialAction-staticTooltipLabel {
    left: 100%;
    /* right: 0 !important; */
    right: -190% !important;
    width: 130px;
    text-align: center;
}


.MuiBackdrop-root {
    z-index: 10 !important;
}

.select_campaign{
    z-index: 100 !important;
}

.modal_modal_campaign{
    z-index: 99 !important;
}

.swal-overlay {
    position: absolute;
    z-index: 99 !important;
}

.img-responsive{
    display: block;
    max-width: 100%;
    height: auto;
    background: white;
    border-radius: 15px;

}

.MuiGrid-spacing-xs-6 > .MuiGrid-item {

    padding-left: 42px !important;
}

.swalModalDatos{
    width: auto;
}

.eraseprizebutton{
    display: inline-flex;
   
}

.backgroundStyle{
    background-size: cover !important;
    /* border-image: linear-gradient( 45deg, turquoise, greenyellow) 1; */
}