.btn1{
  color: rgb(0, 0, 0);
  background-color: #e7d106;
  border-color: #e7d106;
  position: relative;
  z-index: 2;
  font-family: 'Lato';
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.3333333;
  border-radius: 20px;
  font-weight: 505;
  cursor: pointer;
  margin-bottom: 20px;
}

.btnAfterClick1{
  color: rgb(0, 0, 0);
  background-color: #e7d106;
  border-color: #e7d106;
  position: relative;
  z-index: 2;
  font-family: 'Lato';
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.3333333;
  border-radius: 20px;
  font-weight: 505;
  opacity: 60%;
}


#a{
  font-size: 13px;
}

.swal-icon--success__hide-corners {
  width: 0px; 
  height: 0px; 
  background-color: #fff;
  padding: 1px;
  position: absolute;
  left: 28px;
  top: 8px;
  z-index: 1;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.swal-icon--success:after, .swal-icon--success:before {
  content: "";
  border-radius: 50%;
  position: absolute;
  width: 0px;
  height: 0px;
  background: #fff;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
